<template>
	<div
		v-if="!program.not_side"
		class="side bg-333"
	>
		<div
			class="side-container flex-column"
		>
			<ul
				class="side-content overflow-y pb-50"
			>
				<template
					v-for="item in items"
				>
					<li
						v-if="!item.not_view && item.type.indexOf(user.role) > -1"
						:key="item.path"
						class="li-side-content cursor-pointer"
						:class="'li-side-depth-' + item.depth"
					>
						<div
							class="pa-10 justify-space-between"
						>
							<span
								class="font-weight-bold flex-column justify-center ptb-5"
							>{{ item.name }}</span>
						</div>
						<ul>
							<template
								v-for="(sub, index) in item.sub"
							>
								<li
									v-if="!sub.not_view && sub.type.indexOf(user.role) > -1"
									:key="item.path + sub.path + '_' + index"
									class="pa-10 "
									:class="is_selected(item, sub)"
									@click="toPush(item, sub)"
								>
									<div
										class="justify-space-between"
									>
										<span class="flex-column justify-center ptb-5">{{ sub.name }}</span>
										<v-icon
											v-show="!is_selected(item, sub)"
											class="color-icon-dark"
										>mdi mdi-chevron-right</v-icon>
									</div>
								</li>
							</template>
						</ul>
					</li>
				</template>
			</ul>
		</div>
	</div>
</template>

<script>
	export default{
		name: 'Side'
		,props: ['Axios', 'user', 'program']
		,data: function(){
			return {
				items: [
					{
						path: 'Agency'
						,name: '영업점 '
						, type: ['manager']
						, sub: [
							{
								path: 'Company'
								,name: '본사 목록'
								,type: ['manager']
								, params: { type: 'A001001'}
							}
							, {
								path: 'Distributor'
								,name: '총판 목록'
								,type: ['manager']
								, params: { type: 'A001002'}
							}
							, {
								path: 'List'
								,name: '대리점 목록'
								,type: ['manager']
								, params: { type: 'A001003'}
							}
						]
					}
					,{
						path: 'Supply'
						, name: '공급사 '
						, type: ['manager']
						, not_view: true
						, sub: [
							{
								path: 'List'
								,name: '공급사 목록'
								,type: ['manager']
							}
							,{
								path: 'Item'
								,name: '공급사 등록'
								,type: ['manager']
								,not_view: true
							}
							,{
								path: 'Detail'
								,name: '공급사 상세정보'
								,type: ['manager']
								,not_view: true
							}
						]
					}
					,{
						path: 'Member'
						,name: '회원관리'
						,type: ['manager']
						,sub: [
							{
								path: 'List'
								,name: '회원목록'
								,type: ['manager']
							}
							, {
								path: 'OperatorList'
								,name: '영업자 목록'
								,type: ['manager']
							}
						]
					}
					,{
						path: 'Product'
						,name: '상품 관리'
						,type: ['manager']
						,sub: [
							{
								path: 'Category'
								,name: '카테고리'
								,type: ['manager']
							}
							,{
								path: 'List'
								,name: '일반 상품 목록'
								,type: ['manager']
							}
							,{
								path: 'Sales'
								,name: '매입 상품 목록'
								,type: ['manager']
							}
						]
					}
					,{
						path: 'Order'
						,name: '주문 관리'
						,type: ['manager']
						,sub: [
							{
								path: 'List'
								,name: '주문 목록'
								,type: ['manager']
							}
							, {
								path: 'Return'
								,name: '노티 내역'
								,type: ['manager']
							}
						]
					}
					,{
						path: 'Regular'
						,name: '정기결제'
						,type: ['manager']
						,sub: [
							{
								path: 'Management'
								,name: '실행 목록'
								,type: ['manager']
							}
							, {
								path: 'List'
								,name: '정기 결제 목록'
								,type: ['manager']
							}
						]
					}
					,{
						path: 'Settlement'
						,name: '정산 관리'
						,type: ['manager']
						,sub: [
							{
								path: 'Management'
								,name: '정산 실행'
								,type: ['manager']
							}
							,{
								path: 'List'
								,name: '정산 내역'
								,type: ['manager']
							}
							,{
								path: 'DeliveryList'
								,name: '배송비 내역'
								,type: ['manager']
							}
							,{
								path: 'Minus'
								,name: '정산 차감'
								,type: []
							}
						]
					}
					,{
						path: 'Banner'
						,name: '배너 관리'
						,type: ['manager']
						,sub: [
							{
								path: 'List'
								,name: '배너 목록'
								,type: ['manager']
							}
						]
					}
					,{
						path: 'CustomerCenter'
						,name: '고객센터'
						,type: ['manager']
						,sub: [
							{
								path: 'NoticeList'
								,name: '공지사항'
								,type: ['manager']
							}
							,{
								path: 'QnAList'
								,name: '1:1문의'
								,type: ['manager']
							}
							,{
								path: 'ReviewList'
								,name: '상품 리뷰'
								,type: ['manager']
							}
						]
					}
					,{
						path: 'Pg'
						, name: 'PG 관리'
						, type: ['manager']
						, sub: [
							{
								path: 'List'
								, name: 'PG 목록'
								, type: ['manager']
							}
						]
					}
					,{
						path: 'Sms'
						, name: 'SMS 관리'
						, type: ['manager']
						, sub: [
							{
								path: 'List'
								, name: 'SMS 목록'
								, type: ['manager']
							}
						]
					}
					,{
						path: 'Manager'
						, name: '설정'
						, type: ['manager']
						, sub: [
							{
								path: 'List'
								, name: '관리자 목록'
								, type: ['manager']
							}
							,{
								path: 'Setting'
								, name: '관리 설정'
								, type: ['manager']
							}
						]
					}
				]
				,item_new: {

				}
			}
		}
		,computed: {
		}
		,methods: {
			toPush: function(menu, sub){
				console.log('toPush : ' +menu.path + sub.path)
				this.$storage.push({ name: menu.path + sub.path, params: sub.params})
			}
			, is_selected: function(item, sub){
				let t = ''

				if(this.$route.name.indexOf(item.path + sub.path) > -1){
					if(this.$route.params.type){
						if(this.$route.params.type == sub.params.type){
							t = 'bg-black'
						}
					}else{
						t = 'bg-black'
					}
				}
				return t
			}
		}
		,created: function(){
			console.log('in side !!' + this.$route.name)
		}
	}
</script>

<style>

	.side {
		width: 180px;
		height: 100%;
		color: #bbb
	}
	.bg-side {
		width: 100%; height: 100%;
		position: fixed; left: 0; top: 0; z-index: 98;
		opacity: 0.5;
	}
	.side-container{
		height: 100%;
		position: relative;  z-index: 99;
		overflow-y: auto;
		border-right: 1px solid #ddd
	}

	.side-container {

	}

	.li-side-content {
		border-bottom: 1px solid #ddd;
	}
	.li-side-depth-1 {
		text-indent: 0px;
	}
	.li-side-depth-2 {
		text-indent: 10px;
	}
	.li-side-depth-3 {
		text-indent: 20px;
	}
	.li-side-depth-4 {
		text-indent: 30px;
	}
</style>